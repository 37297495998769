<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-body">
				<b-tabs v-model="activeTab">
					<b-tab title="Emailed Alerts">
						<EmailedAlerts />
					</b-tab>
					<b-tab title="Linecards" lazy>
						<LinecardAlerts />
					</b-tab>
					<b-tab title="Alert Banner" lazy>
						<AlertBanners />
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	name: 'AdminNOCAlerts',
	components: {
		EmailedAlerts: () => import('@/view/content/widgets/admin/NOCEmailedAlerts.vue'),
		LinecardAlerts: () => import('@/view/content/widgets/admin/NOCAlertsLinecards.vue'),
		AlertBanners: () => import('@/view/content/widgets/admin/AlertBanners.vue'),
	},
	data() {
		return {
			loaded: false,
			activeTab: 0,
		};
	},
	methods: {},
	created() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'NOC Alerts' }]);
		// show linecards table if linecard id set
		if (this.$route.params.alertid && this.$route.params.alertid.startsWith('l')) {
			this.activeTab = 1;
		}
		this.loaded = false;
	},
};
</script>
